import { initializeEnv } from '@uf-libjs/application';

import { TheLanding } from '@uf-web/components';

import './assets/css/global.scss';

initializeEnv({
  targetId: '#vue-container-default',
  configEnv: process.env.VUE_APP_CONFIG_ENV,
  component: TheLanding,
});
